<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Pedidos</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Relatórios</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <router-link class="text-dark" tag="a" to="/sales/reports">
          <div class="bg-soft-light rounded p-3 mb-2">
            <h6 class="m-0 mt-1">Vendas</h6>
            <p class="m-0">Acompanhe as vendas feitas por período</p>
          </div>
        </router-link>
        <router-link class="text-dark" tag="a" to="/sales/reports">
          <div class="bg-soft-light rounded p-3 mb-2">
            <h6 class="m-0 mt-1">Estados</h6>
            <p class="m-0">Veja para qual estado a loja mais vende</p>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>